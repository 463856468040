// @flow

import React from 'react';
import type { Node } from 'react';
import { find } from '@riseart/fe-utils';
import { CMS_MODULES_COMPONENTS } from 'shared_components/cms/modules';

/**
 * getCmsModule
 *
 * @param {string} moduleKey
 * @returns {Object}
 */
export const getCmsModule = (moduleKey: string): ?Object =>
  CMS_MODULES_COMPONENTS[moduleKey] || null;

/**
 * findCmsModule
 *
 * @param {Array<Object>} placeholders
 * @param {string} key
 * @returns {Object}
 */
export const findCmsModule = (placeholders: Array<Object>, key: string): Object => {
  return find(placeholders, ({ placeholder }) => placeholder === key);
};

/**
 * renderCmsModule
 *
 * @param {Array<Object>} placeholders
 * @returns {Function}
 */
export const renderCmsModule =
  (placeholders: Array<Object>): Function =>
  (key: string, { customModuleKey, loadModule, ...props }: Object = {}): Node => {
    const module = find(placeholders, ({ placeholder }) => placeholder === key);

    if (!module) {
      return null;
    }

    const ModuleComponent = getCmsModule(customModuleKey || module.module);
    const contentProps = module.content ? JSON.parse(module.content) : null;

    // if loadModule flag is set, then load manually the component and return promise
    if (ModuleComponent && loadModule) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        ModuleComponent.load().then((module) => {
          if (module) {
            const [exportName] = Object.keys(module);
            const Component = module[exportName];
            Component ? resolve(<Component {...props} {...contentProps} />) : reject();
          }

          reject();
        });
      });
    }

    return ModuleComponent ? <ModuleComponent {...props} {...contentProps} /> : null;
  };
